<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card shadow-none">
          <div class="card-title p-3 rounded custom-page-header mb-0">
            <div class="d-lg-flex align-items-center">
              <div class="d-flex align-items-center mb-2 mb-lg-0">
                <div class="me-2">
                  <i class="bi bi-shop font-size-24"></i>
                </div>
                <div class="">
                  <h4 class="fw-medium font-size-20 mb-0">
                    {{ title }}  - {{ companyInfo.username }} 
                  </h4>
                  <small class="d-block font-size-13 fw-normal">Account &amp; Company Info, Address Book, and Merchant Orders</small>
                </div>
              </div>
              <div class="ms-auto" v-if="create_modify_permission">
                <router-link
                  :to="{
                    name: prevRoute.name || 'Merchant',
                    query: {
                      status: prevRoute.query.status || $route.query.status,
                    },
                  }"
                  class="btn btn-primary fw-medium me-2"
                >
                  <i class="bi bi-x-lg me-1 text-white"></i> Cancel
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <pre style="height: 400px; font-size: 11px;">{{companyInfo}}</pre>
        <div id="applicant-accordion" class="custom-accordion">
          <div class="card shadow-none border border-custom">
            <a
              href="javascript: void(0);"
              class="text-dark bg-light border-bottom-0 "
              data-toggle="collapse"
              aria-expanded="true"
              aria-controls=""
              v-b-toggle.accordion-1
            >
              <div class="p-3">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-purple"
                      >
                        <i class="bi bi-person font-size-18"></i>
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-0">
                      Account Information <i class="bi bi-exclamation-circle-fill text-danger" v-if="$v.companyInfo.$error"></i>
                    </h5>
                    <p class="text-muted text-truncate mb-0 font-size-13">
                      Please fill in the related fields below
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              data-parent="#applicant-accordion"
              id="accordion-1"
              visible
              accordion="my-accordion"
            >
              <div class="text-center py-5 text-center text-muted border-top" v-if="pageLoading">
                <div
                  class="spinner-border text-secondary my-2"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <br />
                <div>Loading...</div>
              </div>
              <div class="p-3 border-top" v-else>
                <form>
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="mb-3 position-relative">
                        <label>Username <span class="text-muted font-size-12 fw-normal">(Use for login)</span></label>
                        <input
                         disabled
                          v-model.trim="companyInfo.username"
                          type="text"
                          class="form-control"
                          placeholder="Username"
                          :class="{
                            'is-invalid':
                              submitted &&
                              $v.companyInfo.username.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.companyInfo.username.$error"
                          class="invalid-feedback d-block"
                          >
                          <span v-if="!$v.companyInfo.username.required"
                              >Username is required.</span
                              >
                          <span v-else-if="!$v.companyInfo.username.validUsername"
                              >Username must contain only lowercase alphabets and numbers.</span
                              >
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="mb-3 position-relative">
                        <label>First Name</label>
                        <input
                          v-model.trim="companyInfo.first_name"
                          type="text"
                          class="form-control"
                          placeholder="First Name"
                          :class="{
                            'is-invalid':
                              submitted &&
                              $v.companyInfo.first_name.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.companyInfo.first_name.$error"
                          class="invalid-feedback d-block"
                          >
                          <span v-if="!$v.companyInfo.first_name.required"
                              >First Name is required.</span
                              >
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="mb-3 position-relative">
                        <label>Last Name</label>
                        <input
                          v-model.trim="companyInfo.last_name"
                          type="text"
                          class="form-control"
                          placeholder="Last Name"
                          :class="{
                            'is-invalid':
                              submitted &&
                              $v.companyInfo.last_name.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.companyInfo.last_name.$error"
                          class="invalid-feedback d-block"
                          >
                          <span v-if="!$v.companyInfo.last_name.required">Last Name is required.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="mb-3 position-relative">
                        <label>Phone No.</label>
                        <div class="input-group mb-3">
                          <vue-country-code
                              class="input-group-text bg-none p-0 font-size-15"
                              @onSelect="onSelect"
                              ref="vcc"
                              :defaultCountry="returnCountryFromEdit"
                              :enabledCountryCode="true"
                              :onlyCountries="['my','sg', 'th', 'vn','id']">
                            </vue-country-code>
                            <input type="tel" class="form-control" placeholder="Phone No."  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\+\d{1,2})\s?(\d{3})\s?(\d{4})/, '$1$2$3')" v-model="phoneNumber" @input="companyInfo.phone = countryCode + phoneNumber"
                            :class="{
                                'is-invalid rounded': submitted && $v.companyInfo.phone.$error,
                            }">
                            <div
                            v-if="submitted && $v.companyInfo.phone.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.companyInfo.phone.required"
                              >Phone No. is required.</span
                            >
                            <span v-if="!$v.companyInfo.phone.countryCodeAndPhone">
                              Please enter a valid phone no. between 8 and 15 digits
                            </span>
                          
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="mb-3 position-relative">
                        <label>Email Address <span class="text-muted font-size-12 fw-normal">(To Receive Orders)</span></label>
                        <input
                          v-model.trim="companyInfo.email"
                          type="text"
                          class="form-control"
                          placeholder="Email"
                          :class="{
                            'is-invalid':
                              submitted &&
                              $v.companyInfo.email.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.companyInfo.email.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.companyInfo.email.required"
                            >E-mail address is required.</span
                          >
                          <span v-if="!$v.companyInfo.email.email"
                            >E-mail address should be a valid email.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="mb-3 position-relative">
                        <label>Set a Group </label>
                        <select
                          class="form-control select2"
                          v-model="companyInfo.group"
                          :class="{
                            'is-invalid':
                              submitted && $v.companyInfo.group.$error,
                          }"
                        >
                          <option value="">Select</option>
                          <option
                            v-for="(value, index) in groups"
                            :key="index"
                            :value="value"
                          >
                            {{ value.name}}
                          </option>
                        </select>
                        <div
                          v-if="submitted && $v.companyInfo.group.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.companyInfo.group.required"
                            >Group is required.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="mb-3 position-relative">
                        <label>Payment Terms</label>
                        <select
                          class="form-control select2"
                          v-model="companyInfo.payment_terms"
                          :class="{
                            'is-invalid':
                              submitted && $v.companyInfo.payment_terms.$error,
                          }"
                        >
                          <option value="">Select</option>
                          <option
                            v-for="(value, index) in paymentTerms"
                            :key="index"
                            :value="value"
                          >
                            {{ value.name}}
                          </option>
                        </select>
                        <div
                          v-if="submitted && $v.companyInfo.payment_terms.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.companyInfo.payment_terms.required"
                            >Payment terms is required.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="mb-3 position-relative">
                        <label>Account Status</label>
                        <select
                          class="form-control select2"
                          v-model="companyInfo.status"
                          :class="{
                            'is-invalid':
                              submitted && $v.companyInfo.status.$error,
                          }"
                        >
                          <option value="activate">Activate</option>
                          <option value="suspended">Suspended</option>
                          <option value="inactive">Inactive</option>
                        </select>
                        <div
                          v-if="submitted && $v.companyInfo.status.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.companyInfo.status.required"
                            >Status is required.</span
                          >
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-lg-4">
                        <div class="mb-3 position-relative">
                          <label class="d-flex mb-1"><span>Password</span>
                            <button type="button" @click="generatePassword('companyInfo','password','confirmPassword')" class="btn btn-sm btn-info font-size-12 lh-1 py-1 ms-auto">Generate</button>
                          </label>
                          <div class="position-relative">
                            <input
                              v-model="companyInfo.password"
                              :type="passwordFieldType"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                  submitted && $v.companyInfo.password.$error,
                              }"
                              placeholder="Password"
                            />
                            <div
                              v-if="submitted && $v.companyInfo.password.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.companyInfo.password.required"
                                >Password is required.</span
                              >
                              <span v-if="!$v.companyInfo.password.minLength"
                                >Password must be at least 8 characters.</span
                              >

                              <span v-if="!$v.companyInfo.password.alpha">
                                Password must contain at least one number and
                                both lower and uppercase letters and special
                                characters. (e.g.: MercHant357#@, Admin12#@,
                                etc.)</span
                              >
                            </div>
                            <div
                              id="eye"
                              class="user-select-none"
                              @click="switchVisibility()"
                            >
                              <i
                                :class="classicon"
                                id="eyetype"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="mb-3 position-relative">
                          <label class="mb-2">Confirm Password</label>
                          <div class="position-relative">
                            <input
                              v-model="companyInfo.confirmPassword"
                              :type="passwordFieldType2"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                  submitted && $v.companyInfo.confirmPassword.$error,
                              }"
                              placeholder="Confirm Password"
                            />
                            <div
                              v-if="
                                submitted && $v.companyInfo.confirmPassword.$error
                              "
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.companyInfo.confirmPassword.required"
                                >Confirm password is required.</span
                              >
                              <span
                                v-else-if="
                                  !$v.companyInfo.confirmPassword.sameAsPassword
                                "
                                >Confirm password does not match the password.</span
                              >
                            </div>
                            <div
                              id="eye2"
                              class="user-select-none"
                              @click="switchVisibility2()"
                            >
                              <i
                                :class="classicon2"
                                id="eyetype2"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div> -->
                  </div>
                  <div class="mt-2 mb-3">
                    <span class="badge bg-soft-primary font-size-15"
                      >Company Information</span
                    >
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="mb-3 position-relative">
                        <label>Company Name</label>
                        <input
                          disabled
                          v-model.trim="companyInfo.company_name"
                          type="text"
                          class="form-control"
                          placeholder="Company Name"
                          :class="{
                            'is-invalid':
                              submitted &&
                              $v.companyInfo.company_name.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.companyInfo.company_name.$error"
                          class="invalid-feedback d-block"
                          >
                          <span v-if="!$v.companyInfo.company_name.required">Company Name is required.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="mb-3 position-relative">
                        <label>Company Registration No.</label>
                        <input
                          disabled
                          v-model.trim="companyInfo.business_registration_no"
                          type="text"
                          class="form-control"
                          placeholder="20001112222 (123456-A)"
                          :class="{
                            'is-invalid':
                              submitted &&
                              $v.companyInfo.business_registration_no.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.companyInfo.business_registration_no.$error"
                          class="invalid-feedback d-block"
                          >
                          <span v-if="!$v.companyInfo.business_registration_no.required">Company Registration No. is required.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="mb-3 position-relative">
                        <label>Company Email</label>
                        <input
                          v-model.trim="companyInfo.business_email"
                          type="text"
                          class="form-control"
                          placeholder="Company Email"
                          :class="{
                            'is-invalid':
                              submitted &&
                              $v.companyInfo.business_email.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.companyInfo.business_email.$error"
                          class="invalid-feedback d-block"
                          >
                          <span v-if="!$v.companyInfo.business_email.required">Company Email is required.</span>
                          <span v-if="!$v.companyInfo.business_email.email"
                            >E-mail address should be a valid email.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="mb-3 position-relative">
                        <label>Company Address</label>
                        <input
                          v-model.trim="companyInfo.business_address.address"
                          type="text"
                          class="form-control"
                          placeholder="Address Line 1"
                          :class="{
                            'is-invalid':
                              submitted && $v.companyInfo.business_address.address.$error,
                          }"
                        />
                        <input
                          v-model.trim="companyInfo.business_address.address2"
                          type="text"
                          class="form-control mt-1"
                          placeholder="Address Line 2 (Optional)"
                        />
                        <div
                          v-if="
                            submitted && $v.companyInfo.business_address.address.$error
                          "
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.companyInfo.business_address.address.required"
                            >Company Address is required.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 position-relative">
                        <label>City</label>
                        <input
                          v-model.trim="companyInfo.business_address.city"
                          type="text"
                          class="form-control"
                          placeholder="City"
                          :class="{
                            'is-invalid':
                              submitted && $v.companyInfo.business_address.city.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.companyInfo.business_address.city.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.companyInfo.business_address.city.required"
                            >City is required.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 position-relative">
                        <label>Postcode / ZIP</label>
                        <input
                          v-model.trim="companyInfo.business_address.postcode"
                          @input="inputNumberOnlyV3($event,'companyInfo','business_address','postcode')"
                          type="text"
                          class="form-control"
                          placeholder="Postcode / ZIP"
                          :class="{
                            'is-invalid':
                              submitted && $v.companyInfo.business_address.postcode.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.companyInfo.business_address.postcode.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.companyInfo.business_address.postcode.required"
                            >Postcode / ZIP is required.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 position-relative">
                        <label>Country</label>
                        <select
                          class="form-control select2"
                          @change="companyInfo.business_address.states=''"
                          v-model="companyInfo.business_address.country"
                          :class="{
                            'is-invalid':
                              submitted && $v.companyInfo.business_address.country.$error,
                          }"
                        >
                          <option value="">Select</option>
                          <option
                            v-for="(value, index) in southeastAsia"
                            :key="index"
                            :value="value"
                          >
                            {{ value.label}}
                          </option>
                        </select>

                        <div
                          v-if="submitted && $v.companyInfo.business_address.country.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.companyInfo.business_address.country.required"
                            >Country is required.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 position-relative">
                        <label>State / Province</label>
                        <input
                          v-if="companyInfo.business_address.country.value!=='MY'"
                          v-model.trim="companyInfo.business_address.states"
                          type="text"
                          class="form-control"
                          placeholder="State / Province"
                          :class="{
                            'is-invalid':
                              submitted && $v.companyInfo.business_address.states.$error,
                          }"
                        />
                        <select
                          v-else
                          class="form-control select2"
                          v-model="companyInfo.business_address.states"
                          :class="{
                            'is-invalid':
                              submitted && $v.companyInfo.business_address.states.$error,
                          }"
                        >
                          <option value="">Select</option>
                          <option
                            v-for="(value, index) in states"
                            :key="index"
                            :value="value"
                          >
                            {{ value.label}}
                          </option>
                        </select>

                        <div
                          v-if="submitted && $v.companyInfo.business_address.states.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.companyInfo.business_address.states.required"
                            >State / Province is required.</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                </form>
              </div>
            </b-collapse>
          </div>
          <div class="card shadow-none border border-custom">
            <a
              href="javascript: void(0);"
              class="text-dark bg-light collapsed"
              v-b-toggle.accordion-2
            >
              <div class="p-3">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        <i class="bi bi-geo-alt font-size-18"></i>
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                 
                    <h5 class="font-size-16 mb-0">
                      Address Book  <i class="bi bi-exclamation-circle-fill text-danger" v-if="$v.companyInfo.address_book.$error"></i>
                    </h5>
                    <p class="text-muted text-truncate mb-0 font-size-13">
                      Headquarters and Branches
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              id="accordion-2"
              accordion="my-accordion"
              data-parent="#applicant-accordion"
            >
            <div class="text-center py-5 text-center text-muted border-top" 
            v-if="pageLoading">
                <div
                  class="spinner-border text-secondary my-2"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <br />
                <div>Loading...</div>
              </div>
              <div class="p-3 border-top" v-else>
                <div class="row">
                  <div v-for="(v, index) in $v.companyInfo.address_book.$each.$iter" :key="index" class="col-lg-6 col-12" >
                    <div class="bg-light border-custom p-4 rounded-3 mb-3">
                    <div class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="me-3">
                          <div class="avatar-xs">
                            <div
                              class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                            >
                              {{parseInt(index)+1}}
                            </div>
                          </div>
                        </div>
                        <div class="media-body overflow-hidden">
                          <h5 class="font-size-16 mb-0">
                            Address  
                          </h5>
                        </div>
                        <div>
                          <div class="form-check form-switch form-check-custom d-inline-block me-4">
                            <input class="form-check-input" 
                              v-model="v.default.$model" 
                              :index="index" 
                              @change="setDefaultAddress"  
                              true-value="true" 
                              value="true" 
                              name="default" 
                              type="radio" 
                              role="switch" 
                              :id="'default' + index">
                            <label class="form-check-label" :for="'default'+index">Set as Default</label>
                          </div>

                          <div class="form-check form-switch form-check-custom d-inline-block" v-if="index==0">
                            <input class="form-check-input" 
                              @change="useCompanyAddress" 
                              v-model="useCompanyAddressOption"
                              :true-value="true" 
                              :false-value="false" 
                              name="use_company_add" 
                              type="checkbox" 
                              role="switch" 
                              :id="'use_company_add' + index">
                            <label class="form-check-label" :for="'use_company_add'+index">Same as Main Address</label>
                          </div>
                          <button class="btn bg-light border-danger text-danger rounded-pill btn-sm fw-medium" v-if="index!=0" @click="companyInfo.address_book.splice(index, 1);"><i class="bi bi-trash3-fill"></i> Remove</button>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6" :class="{ 'form-group--error': v.$error }">
                        <div class="mb-3 position-relative">
                          <label>First Name</label>
                          <input
                            v-model.trim="v.first_name.$model"
                            type="text"
                            class="form-control"
                            placeholder="First Name"
                            :class="{
                              'is-invalid':
                                submitted &&
                                v.first_name.$error,
                            }"
                          />
                          <div
                            v-if="submitted && v.first_name.$error"
                            class="invalid-feedback d-block"
                            >
                            <span v-if="!v.first_name.required"
                                >First Name is required.</span
                                >
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6" :class="{ 'form-group--error': v.$error }">
                        <div class="mb-3 position-relative">
                          <label>Last Name</label>
                          <input
                            v-model.trim="v.last_name.$model"
                            type="text"
                            class="form-control"
                            placeholder="Last Name"
                            :class="{
                              'is-invalid':
                                submitted &&
                                v.last_name.$error,
                            }"
                          />
                          <div
                            v-if="submitted && v.last_name.$error"
                            class="invalid-feedback d-block"
                            >
                            <span v-if="!v.last_name.required">Last Name is required.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12" :class="{ 'form-group--error': v.$error }">
                        <div class="mb-3 position-relative">
                          <label>Street Address</label>
                          <input
                            v-model.trim="v.address.$model"
                            type="text"
                            class="form-control"
                            placeholder="Address"
                            :class="{
                              'is-invalid':
                                submitted &&
                                v.address.$error,
                            }"
                          />
                          <input
                            v-model.trim="v.address2.$model"
                            type="text"
                            class="form-control mt-2"
                            placeholder="Address 2 (Optional)"
                          />
                          <div
                            v-if="submitted && v.address.$error"
                            class="invalid-feedback d-block"
                            >
                            <span v-if="!v.address.required">Street Address is required.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6" :class="{ 'form-group--error': v.$error }">
                        <div class="mb-3 position-relative">
                          <label>City</label>
                          <input
                            v-model.trim="v.city.$model"
                            type="text"
                            class="form-control"
                            placeholder="City"
                            :class="{
                              'is-invalid':
                                submitted &&
                                v.city.$error,
                            }"
                          />
                          <div
                            v-if="submitted && v.city.$error"
                            class="invalid-feedback d-block"
                            >
                            <span v-if="!v.city.required"
                                >City is required.</span
                                >
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6" :class="{ 'form-group--error': v.$error }">
                        
                        <div class="mb-3 position-relative">
                          <label>Postcode / ZIP</label>
                          <input
                            v-model.trim="v.postcode.$model"
                            @input="inputNumberOnlyV4($event, 'companyInfo','address_book','postcode',index)"
                            type="text"
                            class="form-control"
                            placeholder="Postcode / ZIP"
                            :class="{
                              'is-invalid':
                                submitted &&
                                v.postcode.$error,
                            }"
                          />
                          <div
                            v-if="submitted && v.postcode.$error"
                            class="invalid-feedback d-block"
                            >
                            <span v-if="!v.postcode.required">Postcode / ZIP is required.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6" :class="{ 'form-group--error': v.$error }">
                        <div class="mb-3 position-relative">
                          <label>Country</label>
                          <select
                            class="form-control select2"
                            @change="v.states.$model=''"
                            v-model="v.country.$model"
                            :class="{
                              'is-invalid':
                                submitted && v.country.$error,
                            }"
                          >
                            <option value="">Select</option>
                            <option
                              v-for="(value, index) in southeastAsia"
                              :key="index"
                              :value="value"
                            >
                              {{ value.label}}
                            </option>
                          </select>

                          
                          <div
                            v-if="submitted && v.country.$error"
                            class="invalid-feedback d-block"
                            >
                            <span v-if="!v.country.required"
                                >Country is required.</span
                                >
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6" :class="{ 'form-group--error': v.$error }">
                      <div class="mb-3 position-relative">
                          <label>State / Province</label>
                          <input
                            v-if="v.country.$model.value!=='MY'"
                            v-model.trim="v.states.$model"
                            type="text"
                            class="form-control"
                            placeholder="State / Province"
                            :class="{
                              'is-invalid':
                                submitted && v.states.$error,
                            }"
                          />
                          <select
                            v-else
                            class="form-control select2"
                            v-model="v.states.$model"
                            :class="{
                              'is-invalid':
                                submitted && v.states.$error,
                            }"
                          >
                            <option value="">Select</option>
                            <option
                              v-for="(value, index) in states"
                              :key="index"
                              :value="value"
                            >
                              {{ value.label}}
                            </option>
                          </select>

                          <div
                            v-if="submitted && v.states.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!v.states.required"
                              >State / Province is required.</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-12 text-lg-end">

                   
                      </div>
                    </div>
                    </div>
                  </div>
                  <div
                    class="form-group"
                    :class="{ 'form-group--error': $v.companyInfo.address_book.$error }"
                  ></div>
                  <div class="error" v-if="!$v.companyInfo.address_book.minLength">
                    List must have at least
                    {{ $v.companyInfo.address_book.$params.minLength.min }} elements.
                  </div>
                  <div class="error" v-else-if="!$v.companyInfo.address_book.required">
                    List must not be empty.
                  </div>
                  <div class="error" v-else-if="$v.companyInfo.address_book.$error">
                    Address Book is invalid.
                  </div>

                  <div class="my-2 text-end">
                    <button
                      class="btn btn-info me-1"
                      @click="
                      companyInfo.address_book[0].default = true;
                      companyInfo.address_book.pop()"
                      :disabled="companyInfo.address_book.length < 2"
                    >
                      <i class="uil uil-minus-circle"></i>
                      Remove Address
                    </button>
                    <button
                      class="btn btn-info"
                      @click="
                        companyInfo.address_book.push({
                          first_name: '',
                          last_name: '',
                          address: '',
                          address2: '',
                          city: '',
                          states: '',
                          postcode: '',
                          country:{
                            value: 'MY',
                            label: 'Malaysia'
                          },
                          default: false,
                        })
                      "
                    >
                      <i class="uil uil-plus-circle"></i> Add New Address
                    </button>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <div class="card shadow-none border border-custom">
            <div
              class="text-dark bg-light"
            >
              <div class="p-3">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        <i class="bi bi-bag-check font-size-18"></i>
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-0">Orders History</h5>
                    <p class="text-muted text-truncate mb-0 font-size-13">
                      Complete record of a merchant's past and current orders
                    </p>
                  </div>
                  <button class="btn btn-info" v-if="!pageLoading && companyInfo.total_orders ">View Orders ({{ companyInfo.total_orders }})</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12 col-lg" v-if="!pageLoading && companyInfo.created_by">
        <p class="font-size-12 mb-0 text-muted fw-normal">
              Create by {{companyInfo.created_by}}  on {{formatDateTime(companyInfo.date_created)}}. <br> Last updated on  {{formatDateTime(companyInfo.date_updated)}} by {{ companyInfo.last_update_by }}.
            </p>
      </div>
      <div class="col text-end">
        <button
          class="btn btn-secondary px-3"
          @click="$router.replace({ name: 'Merchant' }).catch(() => {})"
        >
         Cancel
        </button>
        <button
          v-if="create_modify_permission"
          type="button"
          class="btn btn-primary ms-2 px-3"
          @click="save"
          :disabled="loading || buttonLoading[0]"
        >
          <span v-if="buttonLoading[0]"
            > Submitting...</span
          >
          <span v-else> Submit </span>
          <span
            class="spinner-border spinner-border-sm ms-2"
            v-if="buttonLoading[0]"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>
      <!-- end col -->
    </div>
    <Lottie
      :path="'/animate/loading_blue.json'"
      :title="'Loading'"
      :info="'Please wait...'"
      :show="showLottie"
      ref="lottieFunc"
    />
    <Common ref="commonFunc" />
  </Layout>
</template>
<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import { required, email, minLength, helpers } from "vuelidate/lib/validators";
export const equal2A = (value) => {
  return value !== "YOUR_API_ID";
};
export const equal2B = (value) => {
  return value !== "YOUR_API_HASH";
};
const validUsername = (value) => {
  const regex = /^[a-z0-9]+$/;
  return regex.test(value);
};

// const alpha = helpers.regex("alpha", /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);

export const countryCodeAndPhone = (value) => {
const regex = /(\+)\d{8,15}/
return helpers.regex('countryCodeAndPhone', regex)(value)
}

import VueCountryCode from "vue-country-code";
/**
 * Starter page
 */
const PageTitle = "Edit Merchant";

export default {
  components: {
    Layout,
    PageHeader,
    Lottie,
    Common,
    VueCountryCode
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  beforeRouteLeave (to, from , next) {
    if (!this.isDataSizeExceeded()) {
      // Data size is not exceeded, allow navigation without confirmation
      next();
    } else {
      if (!this.completed2Exit) {
        // Data size exceeds the default, show confirmation message
        Swal.fire({
          icon: "info",
          title: "Unsaved Changes",
          html: `Do you really want to leave? You have unsaved changes!`,
          showCancelButton:true,
          iconColor: '#60b725',
          confirmButtonColor: "#222",
          confirmButtonText: "Yes",
        }).then((answer) => {
          if (answer.isConfirmed) {
            next();
          } else {
            next(false);
          }
        });
    } else {
      next();
    }
    }
  },
  data() {
    return {
      completed2Exit:false,
      id: "",
      prevRoute: {
        query: {
          status: "",
        },
      },
      uploadProgress: 0,
      pageLoading: false,
      disabled: false,
      returnData: {},
      accessToken: "",
      accessEmail: "",
      accessPhone: "",
      accessUsername: "",
      account_type: "",
      showLottie: false,
      typeEntity: [
        { name: "Private Limited", value: "private_limited" },
        { name: "Partnership", value: "partnership" },
        { name: "Proprietorship", value: "proprietorship" },
      ],
      countryList: [
        { name: 'Malaysia', dialCode: '60', iso2: 'MY' },
        { name: 'Singapore', dialCode: '65', iso2: 'SG' },
        { name: 'Thailand', dialCode: '66', iso2: 'TH' },
        { name: 'Vietnam', dialCode: '84', iso2: 'VN' },
        { name: 'Indonesia', dialCode: '62', iso2: 'ID' },
      ],
      paymentTerms: [
        { name: "Cash On Delivery (COD)", value: "cod" },
        { name: "Cash Before Delivery (CBD) - 30 Days", value: "cbd_30" },
        { name: "Cash Before Delivery (CBD) - 60 Days", value: "cbd_60" },
        { name: "Cash Before Delivery (CBD) - 90 Days", value: "cbd_90" },
      ],
      returnCountryFromEdit:"MY",
      title: "",
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: "Merchant",
          to: "/merchant",
        },
        {
          text: "",
          active: true,
        },
      ],
      defaultDataSize:482,
      useCompanyAddressOption: false,
      countryCode:"",
      phoneNumber:"",
      groups:[],
      allCountry:[],
      southeastAsia:[],
      states:[],
      passwordFieldType_current: "password",
      classicon_current: "uil uil-eye-slash",
      passwordFieldType: "password",
      classicon: "uil uil-eye-slash",
      passwordFieldType2: "password",
      classicon2: "uil uil-eye-slash",
      companyInfo:{
        username: "",
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        group: "",
        payment_terms:"",
        company_name: "",
        business_registration_no: "",
        business_email: "",
        business_address:{
          address: "",
          address2: "",
          city: "",
          states: "",
          postcode: "",
          country: "",
        },
        address_book: [{
          first_name: "",
          last_name: "",
          address: "",
          address2: "",
          city: "",
          states: "",
          postcode: "",
          country: {
            value: "MY",
            label: "Malaysia"
          },
          default: true,
          }]
      },
      submitted: false,
      loading: false,
      buttonLoading: [false, false, false],
      create_modify_permission:false
    };
  },
  validations: {
    companyInfo:{
      username: {
        required,
        validUsername
      },
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      phone: {
        required,
        countryCodeAndPhone,
        minLength: minLength(10)
      },
      email: {
        required,
        email,
      },
      group: {
        required
      },
      payment_terms: {
        required
      },
      status: {
        required
      },
      company_name:  {
        required,
      },
      business_registration_no:  {
        required,
      },
      business_email: {
        required,
        email,
      },
      business_address:{
        address: {
          required,
        },
        city: {
          required,
        },
        states: {
          required,
        },
        postcode:{
          required,
        },
        country: {
          required,
        }
      },
      address_book: {
        required,
        minLength: minLength(1),
        $each: {
          first_name: {
            required
          },
          last_name: {
            required
          },
          address: {
            required
          },
          address2:{},
          city: {
            required
          },
          states: {
            required
          },
          postcode: {
            required
          },
          country: {
            required
          },
          default:{}
        },
      }
    }
  },
  middleware: "authentication",
  async mounted() {
    await this.reload();
    this.title = PageTitle;
    this.items[2].text = PageTitle;
    this.accessToken = this.$refs.commonFunc.getToken();
    this.accessUsername = this.$refs.commonFunc.getUsername();
    this.getCountryStates();
    this.getGroups();
    this.getData();
  },
  created() {
    // const jsonData = JSON.stringify(this.formData);
    // const dataSize = jsonData.length;
    // console.log(dataSize)
  },
  methods: {
    formatDateTime(dateTime) {
      if (dateTime){
        return dateTime.replace(' ', ' at ');
      }
    },
    onSelect(e){
      console.log(e)
      this.countryCode = '+'+ e.dialCode
    },
    setDefaultAddress:function(e){
      var selectedIndex = e.target.getAttribute('index');
      console.log(selectedIndex)
      this.companyInfo.address_book.forEach((value, index) => {
          if (index == selectedIndex ){
            value.default = true
          }else{
            value.default = false
          }
      });
    },
    separatePhoneNumber(value) {
      if (value) {
        // Regular expression to match country code (1-3 digits after '+')
        const match = value.match(/^\+(\d{1,2})(.*)$/)
        if (match) {
          const countryCode = match[1]
          this.phoneNumber = match[2]
          this.returnCountryFromEdit = this.countryList.find(c => c.dialCode == countryCode).iso2;
        }
      }
    },
    useCompanyAddress:function(e){
      console.log(e, this.useCompanyAddressOption)
      if (this.useCompanyAddressOption){
        this.companyInfo.address_book[0].first_name = this.companyInfo.first_name;
        this.companyInfo.address_book[0].last_name = this.companyInfo.company_name;
        this.companyInfo.address_book[0].address = this.companyInfo.business_address.address;
        this.companyInfo.address_book[0].address2 = this.companyInfo.business_address.address2;
        this.companyInfo.address_book[0].city = this.companyInfo.business_address.city;
        this.companyInfo.address_book[0].postcode = this.companyInfo.business_address.postcode;
        this.companyInfo.address_book[0].country = this.companyInfo.business_address.country;
        this.companyInfo.address_book[0].states = this.companyInfo.business_address.states;
      }else{
        this.companyInfo.address_book[0].first_name = "";
        this.companyInfo.address_book[0].last_name = "";
        this.companyInfo.address_book[0].address = "";
        this.companyInfo.address_book[0].address2 = "";
        this.companyInfo.address_book[0].city = "";
        this.companyInfo.address_book[0].postcode = "";
        this.companyInfo.address_book[0].states = "";
        this.companyInfo.address_book[0].country = this.companyInfo.business_address.country;
      }
    },
    getGroups() {
      console.log("getGroups")
      this.$Progress.start();
      this.loading = true
      this.disabled = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      axios({
        method: "get",
        url: "/api/group_pricing_setting.json",
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data"
        },
      }).then((response) => {
        var resData = response.data;
        if (resData.status == 200) {
          this.groups=[]
          //this.groups=resData.data;
          resData.data.forEach(element => {
            this.groups.push({
              id: element.id,
              account_db_code: element.account_db_code,
              name: element.name
            })
          });

        } else if (resData.status == 440) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
          localStorage.clear();
          this.$router.push({
            path: "/login",
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
        }
        this.loading = false;
        this.disabled = false
        this.$Progress.finish();
      }).catch((error) => {
        this.loading = false;
        this.disabled = false
        this.$Progress.finish();
        //Swal.fire("Error", error, "error");
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `${error}.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        })
      });
    },
    getData() {
      console.log("getData")
      this.$Progress.start();
      this.pageLoading = true
      this.disabled = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("accountDbCode", this.$route.params.id);
      axios({
        method: "get",
        url: "/api/merchants_list.json",
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data"
        },
      }).then((response) => {
        var resData = response.data;
        if (resData.status == 200) {
          var returnData = resData.data.filter(e => e.account_db_code == this.$route.params.id)[0] || {};
          if (returnData.account_db_code){
            this.companyInfo = returnData;
            this.separatePhoneNumber(returnData.phone);

            const jsonData = JSON.stringify(this.companyInfo);
            const dataSize = jsonData.length;
            this.defaultDataSize = dataSize;
          }else{
            this.$router.replace({ name: "Merchant" }).catch(() => {});
          }
        } else if (resData.status == 440) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
          localStorage.clear();
          this.$router.push({
            path: "/login",
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
        }
        this.pageLoading = false;
        this.disabled = false
        this.$Progress.finish();
      }).catch((error) => {
        this.pageLoading = false;
        this.disabled = false
        this.$Progress.finish();
        //Swal.fire("Error", error, "error");
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `${error}.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        })
      });
    },
    getCountryStates() {
      console.log("getCountryStates")
      this.$Progress.start();
      this.loading = true
      this.disabled = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      const uniqueParam = `nocache=${new Date().getTime()}`;
      axios({
        method: "get",
        url: '/api/country_states.json?' + uniqueParam,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data"
        },
      }).then((response) => {
        var resData = response.data;
        if (resData.status == 200) {
          this.allCountry=[]
          this.southeastAsia=[]
          this.states=[]

          this.allCountry=resData.all_country;
          this.southeastAsia=resData.southeast_asia;
          this.states=resData.states;

          this.companyInfo.business_address.country = {
            "value": "MY",
            "label": "Malaysia"
          }

        } else if (resData.status == 440) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
          localStorage.clear();
          this.$router.push({
            path: "/login",
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
        }
        this.loading = false;
        this.disabled = false
        this.$Progress.finish();
      }).catch((error) => {
        this.loading = false;
        this.disabled = false
        this.$Progress.finish();
        //Swal.fire("Error", error, "error");
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `${error}.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        })
      });
    },
    switchVisibility() {
      (this.classicon =
        this.classicon === "uil uil-eye-slash"
          ? "uil uil-eye"
          : "uil uil-eye-slash"),
        (this.passwordFieldType =
          this.passwordFieldType === "password" ? "text" : "password");
    },
     switchVisibility2() {
      (this.classicon2 =
        this.classicon2 === "uil uil-eye-slash"
          ? "uil uil-eye"
          : "uil uil-eye-slash"),
        (this.passwordFieldType2 =
          this.passwordFieldType2 === "password" ? "text" : "password");
    },
    generatePassword(parent, model, model2) {
      const length = 12;
      const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
      let password = "";

      while (!this.isValidPassword(password)) {
        password = "";
        for (let i = 0, n = charset.length; i < length; ++i) {
          password += charset.charAt(Math.floor(Math.random() * n));
        }
      }
      this[parent][model] = password;
      this[parent][model2] = password;
    },
    isValidPassword(password) {
      const hasLowerCase = /[a-z]/.test(password);
      const hasUpperCase = /[A-Z]/.test(password);
      const hasNumber = /[0-9]/.test(password);
      const hasSpecialChar = /[!@#$%^&*()_+]/.test(password);

      return hasLowerCase && hasUpperCase && hasNumber && hasSpecialChar && password.length >= 8;
    },
    isDataSizeExceeded() {
      const jsonData = JSON.stringify(this.companyInfo);
      const dataSize = jsonData.length;
      console.log(dataSize, this.defaultDataSize);
      return dataSize > this.defaultDataSize;
    },
    // contenteditable(index, event){
    //   this.formData.supporting_docs[index].name = event.target.textContent.trim();
    // },
    convertFileSize(fileSize) {
      var formattedFileSize = "";

      if (fileSize < 1024) {
        formattedFileSize = fileSize + " bytes";
      } else if (fileSize < 1048576) {
        // 1024 * 1024
        formattedFileSize = (fileSize / 1024).toFixed(2) + " KB";
      } else if (fileSize < 1073741824) {
        // 1024 * 1024 * 1024
        formattedFileSize = (fileSize / 1048576).toFixed(2) + " MB";
      } else {
        formattedFileSize = (fileSize / 1073741824).toFixed(2) + " GB";
      }
      return formattedFileSize;
    },
    async onChangePDF(e) {
      console.log(e.target.id);
      let file = e.target.files[0];
      console.log(file);
      this.formData.supporting_docs.forEach((element) => {
        if (element.id == e.target.id) {
          element.status = 2;
          element.file_name = file.name;
          element.file_size = this.convertFileSize(file.size);
          element.files = file;
          this.uploadFile(element);
        }
      });
      // this.formSuccessData.transactionReceipt = file
      // this.formSuccessData.transactionReceiptFile = file
    },
    inputNumberOnly(event, parentModel, childModel) {
      const numericValue = event.target.value.replace(/[^0-9-]/g, "");
      this[parentModel][childModel] = numericValue;
    },
    inputNumberOnlyV3(event, parentModel, parent, childModel) {
      const numericValue = event.target.value.replace(/[^0-9-]/g, "");
      this[parentModel][parent][childModel] = numericValue;
    },
    inputNumberOnlyV5(event, parentModel, parent, childModel, child) {
      const numericValue = event.target.value.replace(/[^0-9-]/g, "");
      this[parentModel][parent][childModel][child] = numericValue;
    },
    inputNumberOnlyV4(event, parentModel, parent, childModel, index) {
      const numericValue = event.target.value.replace(/[^0-9-]/g, "");
      this[parentModel][parent][index][childModel] = numericValue;
    },
    notBeforeToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    save() {
      this.submitted = true;
      this.$v.companyInfo.$touch();
      console.log(this.$v.companyInfo.$invalid);
      if (this.$v.companyInfo.$invalid) {
        return;
      } else {
        this.$Progress.start();
        this.buttonLoading[0] = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("username", this.companyInfo.username);
        bodyFormData.append("firstName", this.companyInfo.first_name);
        bodyFormData.append("lastName", this.companyInfo.last_name);
        bodyFormData.append("phone", this.companyInfo.phone);
        bodyFormData.append("email", this.companyInfo.email);
        bodyFormData.append("group",  JSON.stringify(this.companyInfo.group));
        bodyFormData.append("password", this.companyInfo.password);
        bodyFormData.append("companyName", this.companyInfo.company_name);
        bodyFormData.append("businessRegistrationNo", this.companyInfo.business_registration_no);
        bodyFormData.append("businessRmail", this.companyInfo.business_email);
        bodyFormData.append("businessAddress", JSON.stringify(this.companyInfo.business_address));
        bodyFormData.append("addressBook", JSON.stringify(this.companyInfo.address_book));
        axios({
          method: "post",
          url: appConfig.StagingAPI + 'controller/merchant/createNewMerchant',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((response) => {
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData);
              this.completed2Exit = true;
              Swal.fire({
                icon: "success",
                title: "New Merchant",
                html: "Merchant has been created successfully!",
                confirmButtonColor: "#FA6541",
                confirmButtonText: this.$t("siteLang.Done"),
              }).then(() => {
                console.log("success");
                //this.getData()
                this.$router.replace({ name: "Merchant" }).catch(() => {});
              });
            } else if (resData.status == 440) {
              this.completed2Exit = true;
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: `${resData.message}.`,
                confirmButtonColor: "#222",
                confirmButtonText: this.$t("siteLang.Done"),
              });
              localStorage.clear();
              this.$router.push({
                path: "/login",
              });
            } else {
              this.completed2Exit = false;
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: `${resData.message}.`,
                confirmButtonColor: "#222",
                confirmButtonText: this.$t("siteLang.Done"),
              });
            }
            this.buttonLoading[0] = false;
            this.submitted = false;
            this.$Progress.finish();
          })
          .catch((error) => {
            this.buttonLoading[0] = false;
            this.submitted = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${error}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          });
      }
    },
    // save() {
    //   this.submitted = true;
    //   this.$v.formData.$touch();
    //   this.$v.formData2.$touch();

    //   console.log(this.$v.formData.$invalid);
    //   if (this.$v.formData.$invalid || this.$v.formData2.$invalid) {
    //     return;
    //   } else {
    //     this.$Progress.start();
    //     this.buttonLoading[0] = true;
    //     var bodyFormData = new FormData();
    //     bodyFormData.append("accessToken", this.accessToken);
    //     bodyFormData.append("accessUsername", this.accessUsername);
    //     bodyFormData.append("companyName", this.formData.company_name);
    //     bodyFormData.append("businessRegistrationNo", this.formData.business_registration_no);
    //     bodyFormData.append("dateOfRegistration", this.formData.date_of_registration);
    //     bodyFormData.append("typeOfEntity", JSON.stringify(this.formData.type_of_entity));
    //     bodyFormData.append("businessAddress", this.formData.business_address);
    //     bodyFormData.append("businessTel", this.formData.business_tel);
    //     bodyFormData.append("businessEmail", this.formData.business_email);
    //     bodyFormData.append("contactPerson", this.formData.contact_person);
    //     bodyFormData.append("position", this.formData.position);
    //     bodyFormData.append("contactTel", this.formData.contact_tel);
    //     bodyFormData.append("contactEmail", this.formData.contact_email);
    //     bodyFormData.append("directors", JSON.stringify(this.formData.directors));
    //     bodyFormData.append("banks", JSON.stringify(this.formData2.banks));
    //     bodyFormData.append("supportingDocs", JSON.stringify(this.formData.supporting_docs));
    //     bodyFormData.append("currentFacilitiesLimit", JSON.stringify(this.formData.current_facilities_limit));
    //     axios({
    //       method: "post",
    //       url: appConfig.APIHost + 'controller/applicant/createNewApplicant',
    //       data: bodyFormData,
    //       headers: { "Content-Type": "multipart/form-data" },
    //     })
    //       .then((response) => {
    //         var resData = response.data;
    //         if (resData.status == 200) {
    //           console.log(resData);
    //           this.completed2Exit = true;
    //           Swal.fire({
    //             icon: "success",
    //             title: "New Applicant",
    //             html: "Applicant has been successfully added!",
    //             confirmButtonColor: "#FA6541",
    //             confirmButtonText: this.$t("siteLang.Done"),
    //           }).then(() => {
    //             console.log("success");
    //             //this.getData()
    //             this.$router.replace({ name: "Applicant" }).catch(() => {});
    //           });
    //         } else if (resData.status == 440) {
    //           this.completed2Exit = true;
    //           Swal.fire({
    //             icon: "error",
    //             title: "Oops...",
    //             html: `${resData.message}.`,
    //             confirmButtonColor: "#222",
    //             confirmButtonText: this.$t("siteLang.Done"),
    //           });
    //           localStorage.clear();
    //           this.$router.push({
    //             path: "/login",
    //           });
    //         } else {
    //           this.completed2Exit = false;
    //           Swal.fire({
    //             icon: "error",
    //             title: "Oops...",
    //             html: `${resData.message}.`,
    //             confirmButtonColor: "#222",
    //             confirmButtonText: this.$t("siteLang.Done"),
    //           });
    //         }
    //         this.buttonLoading[0] = false;
    //         this.submitted = false;
    //         this.$Progress.finish();
    //       })
    //       .catch((error) => {
    //         this.buttonLoading[0] = false;
    //         this.submitted = false;
    //         this.$Progress.finish();
    //         //Swal.fire("Error", error, "error");
    //         Swal.fire({
    //           icon: "error",
    //           title: "Oops...",
    //           html: `${error}.`,
    //           confirmButtonColor: "#222",
    //           confirmButtonText: this.$t("siteLang.Done"),
    //         });
    //       });
    //   }
    // },
    convertCurrencyFormat(value, show00) {
      if (show00 == true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value);
      } else {
        return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
      }
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type//data.account_type;
          const permission = data.modules.filter(e => e.name==='merchants')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
          } else {
            console.log('Module with name "merchants" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    }
  },
};
</script>
<style scoped>
#eye_current,
#eye,
#eye2,
#eye3{
  position: absolute;
  top: 4px;
  padding: 5px 10px;
  right: 5px;
  text-align: center;
  width: 32px;
  color: #999;
  z-index: 3;
  background-color: #fff;
  cursor: pointer;
}
</style>